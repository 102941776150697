import {useEffect, useState} from 'react';

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaMatch = window.matchMedia(query);
    setMatches(mediaMatch.matches)
    const handler = e => setMatches(e.matches)
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  }, [query, matches]);

  return matches;
};