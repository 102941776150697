import React from "react"
import { Flow } from "../../components/Flow";

export default function FlowPage() {
  return <div className='max-w-6xl mx-auto'>
    <div className='text-3xl'>Long</div>
    <Flow width='12rem' className='bg-primary text-page'>
      {Array(10).fill(undefined).map((ignore, index) => <div className='h-56 flex justify-center'>
        <div className='z-10 w-48 h-48 text-2xl bg-page text-ink rounded-full align-middle text-center'>{index}</div>
      </div>)}
    </Flow>
    <div className='text-3xl'>Short</div>
    <Flow width='12rem' className='bg-primary text-page'>
      {Array(2).fill(undefined).map((ignore, index) => <div className='h-56 flex justify-center'>
        <div className='z-10 w-48 h-48 text-2xl bg-page text-ink rounded-full align-middle text-center'>{index}</div>
      </div>)}
    </Flow>
  </div>
}
