import React from "react";
import { useMediaQuery } from "../hooks/media-query";


export function Flow({ width, className, children }) {
  const md = useMediaQuery(`(min-width: ${flowConfig.md.minWidth})`);
  const lg = useMediaQuery(`(min-width: ${flowConfig.lg.minWidth})`);

  const styles = stylesFor(lg ? "lg" : md ? "md" : null, width, children);

  if (!styles.hasSteps)
    return;

  return <div className={className} style={styles.flow()}>
    <Start styles={styles.start()} />
    {children.map((child, index) => <>
      <Turn key={`turn.${index}`} styles={styles.turn(index)} />
      <Step key={`step.${index}`} styles={styles.step(index)}>{child}</Step>
    </>)}
  </div>;
}

function Start({ styles }) {
  return <div className="relative grid-rows-1 grid-cols-1" style={styles.container}>
    <Line styles={styles.line} />
    <div className={"absolute min-w-0 min-h-0 rounded-full bg-current"} style={styles.bullet} />
  </div>;
}

function Step({ styles, children }) {
  return <div className="relative" style={styles.container}>
    <Line styles={styles.line} />
    {children}
  </div>;
}

function Line({ styles }) {
  return <div className="absolute border-current" style={styles} />;
}

function Turn({ styles }) {
  if (!styles)
    return null;

  return <>
    <Corner styles={styles.top} />
    <Corner styles={styles.bottom} />
  </>;

  function Corner({ styles }) {
    return <div className="relative" style={styles.container}>
      <div className="absolute w-full border-current" style={styles.corner} />
    </div>;
  }
}

const flowConfig = {
  default: {
    flowLine: {
      size: 4,
      unit: "px"
    },
    flowStart: {
      size: 2,
      unit: "rem"
    }
  },
  md: {
    minWidth: "768px",
    columns: 2
  },
  lg: {
    minWidth: "1024px",
    columns: 3
  },
  xl: {
    minWidth: "1280px",
    columns: 4
  }
};

function stylesFor(screenSize, width, steps) {
  const config = {
    ...flowConfig.default,
    width
  };

  if (screenSize)
    Object.assign(config, flowConfig[screenSize]);

  return new Styles(config, width, steps && steps.length);
}

export class Styles {
  constructor(config, width, steps) {
    Object.assign(this, {
      ...config,
      width,
      steps
    });
  }

  get hasSteps() {
    return this.steps;
  }

  get isGrid() {
    return this.columns;
  }

  flow() {
    if (this.isGrid) return {
      display: "grid",
      gridTemplateColumns: `${(this._length(this.flowStart, 2))} repeat(${this.columns}, 1fr) ${(this._length(this.flowStart, 2))}`
    };
  }

  step(step) {
    return this.isGrid ? this._horizontalStep(step) : this._verticalStep(step);
  }

  _verticalStep(step) {
    return {
      line: this.line(step)
    };
  }

  _horizontalStep(step) {
    const { div: row, remainder: column } = this._quotient(step, this.columns);
    const flowsLeft = row % 2;

    return {
      container: {
        gridColumnStart: flowsLeft ? 1 + this.columns - column : 2 + column,
        gridRowStart: row + 1
      },
      line: this.line(step, flowsLeft)
    };
  }

  turn(step) {
    return this.isGrid ? this._horizontalTurn(step) : null;
  }

  _horizontalTurn(step) {
    const { div: row, remainder: midFlow } = this._quotient(step, this.columns);
    if (midFlow || step === 0)
      return null;

    const flowsLeft = this._flowsLeft(step);

    return {
      top: {
        container: {
          gridRowStart: row,
          gridColumnStart: flowsLeft ? this.columns + 2 : 1
        },
        corner: {
          height: `calc(100% + ${this._length(this.flowLine, .5)} - ${this.width} / 2)`,
          top: `calc(${this.width} / 2 - ${this._length(this.flowLine, .5)})`,
          borderWidth: flowsLeft ? `${this._length(this.flowLine)} ${this._length(this.flowLine)} 0 0`
            : `${this._length(this.flowLine)} 0 0 ${this._length(this.flowLine)}`,
          borderRadius: flowsLeft ? `0 ${this._length(this.flowStart, 2)} 0 0` : `${this._length(this.flowStart, 2)} 0 0 0`
        }
      },
      bottom: {
        container: {
          gridRowStart: row + 1,
          gridColumnStart: flowsLeft ? this.columns + 2 : 1
        },
        corner: {
          height: `calc(${this.width} / 2 + ${this._length(this.flowLine, .5)})`,
          borderWidth: flowsLeft ? `0 ${this._length(this.flowLine)} ${this._length(this.flowLine)} 0`
            : `0 0 ${this._length(this.flowLine)} ${this._length(this.flowLine)}`,
          borderRadius: flowsLeft ? `0 0 ${this._length(this.flowStart, 2)} 0`
            : `0 0 0 ${this._length(this.flowStart, 2)}`
        }
      }
    };
  }

  line(step) {
    return this.isGrid ? this._horizontalLine(step)
      : this._verticalLine(step);
  }

  _horizontalLine(step) {
    const isLast = this._lastStep(step);
    return {
      borderWidth: `0 0 ${this._length(this.flowLine)} 0`,
      width: isLast ? "50%" : "100%",
      height: 0,
      left: isLast && this._flowsLeft(step) ? "50%" : 0,
      top: `calc(${this.width} / 2 - ${this._length(this.flowLine, .5)})`
    };
  }

  _verticalLine(step) {
    return {
      borderWidth: `0 ${this._length(this.flowLine)} 0 0`,
      width: 0,
      height: this._lastStep(step) ? `calc(${this.width} / 2)` : "100%",
      left: `calc(50% - ${this._length(this.flowLine, .5)})`
    };
  }

  start() {
    return this.isGrid ? this._horizontalStart() : this._verticalStart();
  }

  get _startBullet() {
    return {
      width: this._length(this.flowStart),
      height: this._length(this.flowStart)
    };
  }

  _horizontalStart() {
    return {
      line: this.line(),
      container: {
        minWidth: this._length(this.flowStart, 2),
        minHeight: this._length(this.flowStart)
      },
      bullet: {
        ...this._startBullet,
        top: `calc(${this.width} / 2 - ${this._length(this.flowStart, .5)})`
      }
    };
  }

  _verticalStart() {
    return {
      line: this.line(),
      container: {
        minWidth: this._length(this.flowStart),
        minHeight: this._length(this.flowStart, 2)
      },
      bullet: {
        ...this._startBullet,
        left: `calc(50% - ${this._length(this.flowStart, .5)})`
      }
    };
  }

  _length({ size, unit }, factor = 1) {
    return `${size * factor}${unit}`;
  }

  _quotient(nominal, fraction) {
    const remainder = nominal % fraction;
    return { div: (nominal - remainder) / fraction, remainder };
  }

  _flowsLeft(step) {
    const { div: row } = this._quotient(step, this.columns);
    return row % 2;
  }

  _lastStep(step) {
    return step === this.steps - 1;
  }
}